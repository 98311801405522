var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.page
    ? _c(
        "section",
        { staticClass: "voice-code" },
        [
          _c("page-header", {
            staticClass: "mb-xs",
            attrs: {
              "show-back-icon": "",
              title: _vm.currentProject.name + " / " + _vm.page.name,
              "show-status": "",
              status: _vm.page.ispublic
            },
            on: {
              back: function($event) {
                return _vm.$router.go(-1)
              }
            }
          }),
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading, tip: _vm.$t("Loading...") } },
            [
              _c("basic-info", {
                attrs: {
                  "page-id": +_vm.pageId,
                  title: _vm.title,
                  "created-date": _vm.createdDate,
                  size: _vm.size,
                  "ec-level": _vm.ecLevel,
                  categoryId: _vm.categoryId
                },
                on: {
                  changeTitle: _vm.changeTitle,
                  changeCreatedDate: _vm.changeCreatedDate,
                  changeCodeSize: _vm.changeCodeSize,
                  changeErrorEcLevel: _vm.changeErrorEcLevel,
                  changeCategory: _vm.changeCategory
                }
              }),
              _c("advanced-info", {
                attrs: {
                  thumbnail: _vm.thumbnail,
                  linkURL: _vm.linkURL,
                  janCodes: _vm.janCodes,
                  tags: _vm.tags
                },
                on: {
                  changeOriginalLink: _vm.changeOriginalLink,
                  removeJanCode: _vm.removeJanCode,
                  addJanCode: _vm.addJanCode,
                  changeThumbnail: _vm.changeThumbnail,
                  changeTags: _vm.changeTags
                }
              }),
              _c("dictionary-info", {
                attrs: {
                  codeDictionary: _vm.dictionary,
                  projectDictionary: _vm.projectDictionary,
                  pageDictionary: _vm.pageDictionary
                },
                on: { changeDictionary: _vm.changeDictionary }
              }),
              _c(
                "a-tabs",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "manual" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "tab" }, slot: "tab" },
                        [
                          _c("a-icon", { attrs: { type: "file-add" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("Manual")))])
                        ],
                        1
                      ),
                      _c("a-list", {
                        attrs: {
                          "data-source": _vm.codes,
                          grid: { gutter: 16, xs: 1, sm: 1, md: 1, lg: 1 },
                          locale: { emptyText: _vm.$t("No data") }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "renderItem",
                              fn: function(item) {
                                return _c(
                                  "a-list-item",
                                  {},
                                  [
                                    _c("plain-code-item", {
                                      attrs: {
                                        status: "DRAFT",
                                        code: item,
                                        "lang-options": _vm.noUseLangs,
                                        lexicon: _vm.page.lexicon || [],
                                        engine: _vm.pollyEngine || "standard",
                                        dictionary:
                                          (_vm.dictionary.length > 0 &&
                                            _vm.dictionary) ||
                                          (_vm.pageDictionary.length > 0 &&
                                            _vm.pageDictionary) ||
                                          (_vm.projectDictionary.length > 0 &&
                                            _vm.projectDictionary) ||
                                          []
                                      },
                                      on: {
                                        changeLang: _vm.updateLang,
                                        changeCodeContent: _vm.updateContent,
                                        changeSSMLContent:
                                          _vm.changeSSMLContent,
                                        enableSSML: _vm.enableSSML,
                                        deleteCode: _vm.deleteCodeItem,
                                        onPlaySpeech: _vm.handlePlaySpeech,
                                        onPauseSpeech: _vm.handlePauseSpeech,
                                        openPreviewCodeContent:
                                          _vm.openPreviewCodeContent,
                                        catchSSMLError: _vm.catchSSMLError
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          275333524
                        )
                      }),
                      _c(
                        "a-button",
                        {
                          staticClass: "mt-xs mb-md",
                          on: { click: _vm.addCode }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Add Language")) + " ")]
                      ),
                      _c(
                        "a-row",
                        {
                          attrs: {
                            gutter: [16, 16],
                            type: "flex",
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 24, lg: 5 } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "dashed",
                                    icon: "eye",
                                    disabled: _vm.deActiveButton
                                  },
                                  on: { click: _vm.previewQRCode }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Show demo")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 24, lg: 5 } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "primary",
                                    ghost: "",
                                    icon: "save",
                                    disabled: _vm.deActiveButton
                                  },
                                  on: { click: _vm.saveCodeDraft }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Save as draft")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 24, lg: 5 } },
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placement: "top",
                                    title: _vm.$t(
                                      "Are you sure to generate code?"
                                    ),
                                    "ok-text": _vm.$t("Yes"),
                                    "cancel-text": _vm.$t("No"),
                                    disabled: _vm.deActiveButton
                                  },
                                  on: { confirm: _vm.saveCodePublish }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      class: {
                                        blink: true,
                                        blinking: _vm.isDefaultDataChanged
                                      },
                                      staticStyle: { width: "100%" },
                                      attrs: { disabled: _vm.deActiveButton }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "15px",
                                          height: "15px",
                                          "margin-right": "5px"
                                        },
                                        attrs: {
                                          src: require("@/assets/logo.png")
                                        }
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Generate")) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "import" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "tab" }, slot: "tab" },
                        [
                          _c("a-icon", { attrs: { type: "import" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("Import File")))])
                        ],
                        1
                      ),
                      _c("import-file", {
                        attrs: { categoryId: _vm.categoryId },
                        on: { onSaveCodesList: _vm.saveCodesList }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: { title: _vm.$t("Preview") },
              model: {
                value: _vm.previewCodeContentModal,
                callback: function($$v) {
                  _vm.previewCodeContentModal = $$v
                },
                expression: "previewCodeContentModal"
              }
            },
            [
              _c("div", {
                staticClass: "content-group",
                domProps: { innerHTML: _vm._s(_vm.contentPreview) }
              }),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: {
                        click: function() {
                          return (_vm.previewCodeContentModal = false)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("preview-qrcode", { ref: "previewQRRef" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }